import { fetcher } from '../fetcher';

const EMPLOYEES_ENDPOINT = '/employees';

export const employeesService = {
  getMedics: async () => {
    return await fetcher(`${EMPLOYEES_ENDPOINT}/medic`, {
      method: 'GET',
      schema: personelsSchema,
    });
  },
  getNurses: async () => {
    return await fetcher(`${EMPLOYEES_ENDPOINT}/nurse`, {
      method: 'GET',
      schema: personelsSchema,
    });
  },
  getCoordinators: async () => {
    return await fetcher(`${EMPLOYEES_ENDPOINT}/coordinator`, {
      method: 'GET',
      schema: personelsSchema,
    });
  },
  getEmployees: async ({
    search,
    page,
    per_page = 10,
  }: {
    search: string;
    page: number;
    per_page?: number;
  }) => {
    return await fetcher(
      `${EMPLOYEES_ENDPOINT}?search=${search}&page=${page + 1}&per_page=${per_page}`,
      {
        method: 'GET',
        schema: personelMetaSchema,
        meta: true,
      },
    );
  },
  getAllEmployees: async () => {
    return await fetcher(`${EMPLOYEES_ENDPOINT}?per_page=1000000000`, {
      method: 'GET',
      schema: personelMetaSchema,
      meta: true,
    });
  },
};
